import React from "react";

export default function BarsIcon() {
   return (
      <div className="bar-icon">
         <div />
         <div />
         <div />
      </div>
   );
}
